<template>
  <div class="about-nav">
    <div class="wrap">
      <div class="about-navs clearfix">
        <span class="about-box">
          <router-link
            to="/"
            tag="span"
            class="about-list"
          >{{$t('about.home')}}</router-link>
          <span class="about-lists">
            <i class="about-listicon iconfont icon-youbianjiantou"></i>
            <router-link
              tag="span"
              to="/about"
              class="about-info"
            >
              {{$t('about.about')}}
            </router-link>
            <i class="about-listicon iconfont icon-youbianjiantou"></i>
          </span>

          <span class="about-lists">

            <span
              class="about-info"
              @click="$router.push(item.path)"
              v-for="item in aboutBreadcrumb"
              :key="item.label"
            >
              <span class="about-info-list">
                <i class="iconfont icon-youbianjiantou"></i>
                {{$t(item.label)}} </span>
            </span>
          </span>

        </span>

        <span
          class="return"
          @click="onClickBack(back.path)"
          v-for="back in tobackList"
          :key="back.label"
        >
          <span>{{$t(back.label)}}</span>
          <img
            :src="back.image"
            alt=""
          ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    aboutBreadcrumb: {
      required: true,
    },
    tobackList: {
      required: true,
    },
  },
  methods: {
    onClickBack(link) {
      this.$router.push({
        path: link,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.about-nav {
  padding: 30px 0;
  border-bottom: 1px solid #eeeeee;
  position: relative;

  .about-navs {
    width: 100%;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;

    .about-box {
      width: 70%;

      .about-list {
        color: #000;
        margin-left: 30px;
      }

      .about-lists {
        .about-listicon {
          display: inline-block;
          padding: 0 10px;
        }
        .about-info {
          color: #666;
          display: inline-block;
          cursor: pointer;

          &:nth-child(2) {
            .about-info-list{
              position: relative;
              top: 2px;
              left: 0;
              overflow: hidden;
              display: inline-block;
            }
          }

          &-list {
            display: inline-block;
            width: 300px;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-all;

            .icon-youbianjiantou {
              margin: 0 10px;
            }
          }

          &:nth-child(1) {
            .about-info-list {
              width: auto;
            }

            .icon-youbianjiantou:nth-child(1) {
              display: none;
            }
          }
        }
      }
    }

    .return {
      width: 30%;
      display: inline-block;
      text-align: right;
      position: relative;
      cursor: pointer;

      img {
        position: relative;
        top: -1px;
        left: 4px;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .about-nav,
  .about-nav .about-navs {
    width: 1200px !important;
  }

  .about-nav .about-navs .return img {
    left: 0;
  }
}
</style>
